.footer-container{
    background-color: var(--sec-bg-color);
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
}

.footer-social-links-container{
    position: relative;
    display: flex;
    margin: 0 auto;
}

.footer-social-link{
    margin: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.f-ic{
    height: 100%;
    width: 100%;
}

.f-ic > *{
    height: 100%;
    width: 100%;
    color: var(--main-bg-color);
}

.footer-owner-container{
    position: relative;
    display: flex;
    margin: 0 auto;
}

.footer-owner-container > div{
    color: var(--main-bg-color);
}