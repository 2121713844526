#projects{
    transform: translateY(-50px);
}

.projects-container{
    background-color: var(--sec-bg-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
    }

    .projects-title {
        color: var(--main-bg-color);
        text-align: center;
        width: fit-content;
        margin: 0 auto 10px;
        padding: 15px;
        font-size: 25px;
        border-bottom: 3px solid var(--main-bg-color);
    }

    .projects-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    background-color: var(--sec-bg-color);
}

.project-card{
    position: relative;
    margin: 10px auto;
    align-items: center;
    border: 1px solid #000;
    padding: 15px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px 2px;
}

.project-card-img{
    height: 200px;
    width: 300px;
    background-color: var(--main-bg-color);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.5) 0px 1px 5px 2px;
    overflow: hidden;
    transition-duration: 300ms;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}

/* .project-card-img:hover {
    transform: scale(1.15) translateY(-20px);
    z-index: 1;
} */

.project-card-img-card{
    /* height: 400px; */
    width: 100%;
    top: 0;
    transform: translateY(10%);
}

.project-card-tech-spec-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.project-card-tech-spec-chip{
    color: rgb(150, 9, 9);
}

.project-card-description-container{
    color: #000;
}

.project-card-btn-container{
    display: flex;
    /* justify-content: space-around; */
}

.project-card-btn{
    background-color: var(--main-bg-color);
    margin: 5px;
    display: flex;
    justify-content: space-around;
    text-align: justify;
    align-items: center;
    height: 30px;
    width: calc(50%/2);
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 10px;
}
@media only screen and (max-width: 770px){
    .projects-container{
        grid-template-columns: auto;
        padding: 0;
    }   

    .projects-grid{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .project-card-img{
        height: 150px;
        width: 250px;
    }
}