.about-container{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto}

.about-img{
    width: calc(100vw / 2);
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(50px);
}

.about-img-border{
    border: 4px solid var(--sec-bg-color);
    border-radius: 20px;
}

.about-my-pic{
    height: 400px;
    transform: translate3d(50px, -40px, 20px);
    border-radius: 20px;
}

.about-me {
    padding: 40px;
}

.about-me>h1,
div {
    text-align: start;
    overflow-wrap: break-word;
}

.about-me h1{
    font-size: 20px;
}
.about-me > div{
    font-size: 18px;
}

.about-me-technologies-container{
    margin-top: 5rem;
    padding: 40px;
    grid-column-start: 1;
    grid-column-end: 3;
}

.about-me-technologies-title{
    text-align: center;
    font-size: 25px;
    margin-bottom: 5px;
}

.about-me-technology{
    position: relative;
    display: flex;
    margin: 5px 0;
}

.about-me-technologies-img-container {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
}

.about-me-technology-img {
    margin-right: 10px;
    width: 150px;
    height: 150px;
    padding: 10px;
    border-radius: 20%;
    background-color: #f5f5f5;
    transition-duration: 300ms;
}

.about-me-technology-img:hover {
    transform: scale(1.2);
    z-index: 1;
}

@media only screen and (max-width: 770px){
    .about-container{
        grid-template-columns: auto;
        width: 100%;
    }

    .about-img{
        margin: 10px auto;
    }

    .about-img-border{
        border: none;
        border-radius: 0;
    }
    
    .about-my-pic{
        height: auto;
        width: 100%;
        transform: translate3d(0, 0, 0);
        border-radius: 0;
    }
    
    .about-me-container{
        width: 100%;
        padding: 1rem 1rem;
        border-left: none;
    }
}
@media only screen and (max-width: 450px){
    .about-container{
        grid-template-columns: auto;
        width: 100%;
    }

    .about-img{
        margin: 0;
        width: 100%;
        display: none;
    }

    .about-img-border{
        width: 100%;
    }
    
    .about-my-pic{
        height: auto;
        width: 100%;
        transform: translate3d(0, 0, 0);
        border-radius: 0;
    }
}