.header{
    height: 100vh;
    width: 100%;
    background-image: url('https://res.cloudinary.com/dnbq6nf5d/image/upload/v1630326612/Portfolio%20Assets/markus-spiske-FXFz-sW0uwo-unsplash2_i2q7xe.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: relative;
    }

    .bgImg {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
}

.header-overlay{
    display: flex;
    justify-content: flex-end;
    background-color: rgba(58, 78, 72, 0.3), rgba(58, 78, 72, 0.3) ;
    width: 100%;
    height: 100%;
}

.header-title-container{
    margin-top: calc(50vh - 100px);
    margin-right: 100px;
    display: flex;
    flex-direction: column;
}

.header-title-container > * {
    cursor: default;
    text-align: center;
    font-family: 'Shippori Mincho', serif;
}

.header-title-name{
    font-size: 40px;
    font-weight: bold;
}

.header-title-occupation{
    font-size: 20px;
}

.header-fade{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background-image: linear-gradient(
        180deg,
        transparent,
        rgba(58, 78, 72, 0.4),
        #2b3a35
    );
    display: flex;
    justify-content: center;
}

.header-downBtn{
    cursor: pointer;
    animation-name: down-btn;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    }

    @keyframes down-btn {
        0% {
            transform: translateY(0);
        }

        50% {
            transform: translateY(10px);
        }

        100% {
            transform: translateY(0);
        }
}

.h-ic{
    cursor: pointer;
}

.h-ic > * {
    color: var(--main-bg-color);
}

@media only screen and (max-width: 770px){
    .header-title-container{
        margin-top: calc(50vh - 100px);
        margin-right: 80px;
    }

    .header-title-name{
        font-size: 40px;
    }
    
    .header-title-occupation{
        font-size: 20px;
    }
}

@media only screen and (max-width: 450px){
    .header-title-container{
        margin-top: calc(50vh - 100px);
        margin-right: 20px;
    }

    .header-title-name{
        font-size: 20px;
    }

    .header-title-occupation{
        font-size: 12px;
    }

    .header-fade{
        display: none;
    }
}