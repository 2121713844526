.nav{
    z-index: 1;
    height: 40px;
    width: 100%;
    position: fixed;
    top: 0;
}

.nav-container{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    transition: all 0.5s ease-in-out;
}

.nav-container-background{
    background: var(--main-bg-color);
}

.nav-logo{
    padding-left: 4rem;
    cursor: default;
    position: relative;
    width: 20%;
    margin: auto 0;
    display: flex;
    align-items: center;
}

.nav-logo h1{
    font-size: 23px;
    animation: logoslide 3s;
}

@keyframes logoslide{
    0% {left: -1000%}
    50% {left: 50%}
    100% {left: 4rem}
}

.menu-btn{
    display: none;
}

.menu-btn > div{
    background-color: var(--main-bg-color);
    height: 3px;
    width: 25px;
    margin: 3px 0;
    border-radius: 10px;
    transition: all 0.25s ease-in-out;
}

.menu-btn-color > div{
    background-color: var(--sec-bg-color);
}

.toggle > .line1{
    transform: rotate(-45deg) translate(3px, 7px);
}

.toggle > .line2{
    display: none;
}

.toggle > .line3{
    transform: rotate(45deg) translate(3px, -7px);
}

.nav-list-container{
    position: relative;
    margin-left: auto;
    padding-right: 2rem;
    width: 30%;
}

.nav-list{
    width: 100%;
    display: flex;
}

.nav-list > a{
    text-decoration: none;
    margin: 0rem auto;
}


.nav-list > .active{
    color: grey;
}

.nav-list-items{
    color: var(--sec-bg-color);
    font-size: 14px;
    cursor: pointer;
}

.nav-list-items:hover{
    transition: all ease-in-out 0.5s;
    border-bottom: 1px solid  var(--sec-bg-color);
}

.mobile-nav-list-container{
    position: absolute;
    height: calc(100vh - 40px);
    width: 100%;
    right: 0;
    top: 40px;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
    justify-content: flex-end;
    overflow: hidden;
}

.mobile-nav-list{
    background-color: #111111;
    display: flex;
    width: 75%;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
}

.mobile-nav-list-background{
    background-color: #2b3a35;
}

.mobile-nav-list-items{
    height: 40px;
    padding: 5px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid black;
}

@media only screen and (max-width: 770px){
    .nav-logo{
        width: 40%;
    }

    .nav-logo h1{
        animation: none;
    }
}

@media only screen and (max-width: 450px){
    .nav{
        z-index: 1;
        height: 40px;
    }
    .nav-container{
        z-index: 1;
        flex-wrap: wrap;
        text-align: left;
        justify-content: left;
        align-items: center;
        height: 100%;
    }

    .nav-logo{
        margin: auto 0;
        margin-left: 1rem;
        margin-right: auto;
        padding: 0;
        height: 100%;
        align-items: center;
        font-size: medium;
        width: 50%;
    }

    .nav-logo h1{
        animation: none;
        position: relative;
        align-self: center;
        justify-self: center;
        display: none;
    }

    .menu-btn{
        display: block;
        margin-right: 1rem;
    }

    .nav-list-container{
        display: none;
    }

    
    .mobile-nav-list-container{
        display: flex;
    }
    
    .nav-show{
        animation: nav-show 0.3s;
        transform: translateX(0);
    }
    .nav-hide{
        animation: nav-hide 0.3s;
        transform: translateX(100%);
    }

    @keyframes nav-show{
        0% {transform: translateX(100%)}
        100% {transform: translateX(0)}
    }

    
    @keyframes nav-hide{
        0% {transform: translateX(0)}
        100% {transform: translateX(100%)}
    }

}