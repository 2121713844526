.contact-container{
    margin: 25px 0;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-title{
    font-size: 25px;
}

.contact-divide-line{
    width: 10%;
    margin: 20px 0;
    background: #c8b4ba;
    border: 1px solid #c8b4ba;
    align-self: center;
}

.contact-form{
    width: 35%;
    margin: 0 auto;
    text-align: left;
}

.contact-form form{
    display: flex;
    flex-direction: column;
}

.contact-form-input{
    margin: 0 0 15px;
    padding: 10px 5px;
    font-size: 16px;
    outline: none;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    cursor: text;
    color: #867a7e;
}

textarea{
    max-width: 98%;
    min-width: 98%;
    max-height: 120px;
    min-height: 120px;
}

.contact-form-submit{
    background: #4c665e;
    cursor: pointer;
    color: var(--sec-bg-color);
}

.errors{
    color: orange;
    margin: 0 auto;
}

@media only screen and (max-width: 770px){
    .contact-divide-line{
        width: 40%;
    }

    .contact-form{
        width: 50%;
    }
}

@media only screen and (max-width: 450px){
    .contact-divide-line{
        width: 40%;
    }

    .contact-form{
        width: 80%;
    }
}