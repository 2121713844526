:root {
  --main-bg-color: #2b3a35;
  --sec-bg-color: #f5f5f5;
  --main-txt-color: #c8b4ba;
  --main-padding: 15px;
  scroll-behavior: smooth;
}

*{
  list-style: none;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  color: var(--sec-bg-color);
}

.App {
  text-align: center;
  overflow: hidden;
  background-color: #2b3a35;
}