.Downloadbtn-container{
    position: fixed;
    right: 50px;
    bottom: 25px;
    z-index: 1;
    cursor: pointer;
}

.Downloadbtn-container:hover {
    animation-name: shim;
    animation-duration: 600ms;
    animation-iteration-count: infinite;
}

@keyframes shim {
    0% {
        transform: rotate(0deg)
    }

    25% {
        transform: rotate(-10deg)
    }

    50% {
        transform: rotate(0deg)
    }

    75% {
        transform: rotate(10deg)
    }

    100% {
        transform: rotate(0deg)
    }
}

.Downloadbtn{
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: var(--sec-bg-color);
    display: flex;
    align-items: center;
    justify-content: center;
}

.download-ic{
    font-size: 31px;
}

.download-ic > *{
    color: var(--main-bg-color);
}

@media only screen and (max-width: 768px){
    .Downloadbtn{
        height: 50px;
        width: 50px;
    }
}